import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  ANNOUNCEMENT_REACHABLE_AUDIENCE_COUNT_QUERY,
  AVAILABLE_PERMISSIONS,
} from 'client-lib';
import debounce from 'lodash/debounce';
import GroupMessageTemplate from '../GroupMessageTemplate';
import SaveAndExit from './subComponents/SaveAndExit.tsx';
import OverviewItem from './subComponents/OverviewItem';
import { PageError } from './GroupMessageContent';
import { Loading, Modal } from '../../../../elements';
import {
  openSnackbar,
  setAnnouncementStatus,
  triggerRefetchAnnouncement,
  triggerRefetchBroadcast,
} from '../../../../actions/general';
import MessageSubTitle from './subComponents/MessageSubTitle';
import RecipientSubTitle from './subComponents/RecipientSubTitle';
import AnnouncementPermissionCheck from './subComponents/AnnouncementPermissionCheck';
import { ANNOUNCEMENT_TYPES } from '../GroupMessageWizard';
import GroupMessageOverviewDetails from '../../shared/OverviewDetails.tsx';
import GroupMessageOverviewName from './subComponents/GroupMessageOverviewName';
import useGetUserAccountPolicies from '../../../../hooks/customer/useGetUserAccountPolicies';
import SchedulingFlyout from './subComponents/SchedulingFlyout/SchedulingFlyout.tsx';
import BroadcastRecurringFlyout from '../../../Announcements/Flyouts/BroadcastRecurringFlyout.tsx';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 850px;
  max-width: 85%;
  position: relative;
  padding-top: 10px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  height: 90%;
`;

const GroupMessageOverviewCombined = ({
  wizardState,
  wizardStateSetters,
  closeWizard,
  setWizardPage,
  mutations,
  wizardGlobalProps,
}) => {
  const client = useApolloClient();
  const { data, loading, error } = wizardGlobalProps.announcementQuery;
  const { sendLoading } = wizardGlobalProps.sendAnnouncement;
  const { announcementId, announcementType } = wizardState;
  const { setAnnouncementId, setAnnouncementType } = wizardStateSetters;
  const {
    createAnnouncementMutation,
    updateAnnouncementMutation,
    sendAnnouncementMutation,
  } = mutations;

  const currentUser = useSelector((state) => state?.session?.currentUser);

  useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.RECUR_BTM],
    userId: currentUser?.userId,
  });

  const timezone = moment.tz.zone(moment.tz.guess()).abbr(moment());
  const dispatch = useDispatch();
  const [hasPermission, togglePermission] = useState(false);
  const [pageError, setPageError] = useState('');
  const [messageError, setMessageError] = useState('');
  const GENERIC_ERR = i18n.t('slideouts-GroupMessageName-genericError');
  const BLANK_ERR = i18n.t('slideouts-GroupMessageName-blankError');

  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const [deliveryDateTime, setDeliveryDateTime] = useState();
  const [deliveryError, setDeliveryError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const [messageName, setMessageName] = useState(
    data?.announcement?.announcement?.subject ?? ''
  );

  const [outboundChannelId, setOutboundChannelId] = useState(
    data?.announcement?.announcement?.outboundChannel?.id ??
      wizardGlobalProps?.channelsData?.channels[0]?.id
  );

  const [loadedRouteToGroup, setLoadedRouteToGroup] = useState(false);
  const [routeToGroup, setRouteToGroup] = useState(
    !!data?.announcement?.announcement?.routeToGroupId
  );

  const [routeToGroupId, setRouteToGroupId] = useState(
    data?.announcement?.announcement?.routeToGroupId
  );

  // selected audience data
  // Data for Selected Audience counts
  const { data: selectedAudienceCount, refetch } = useQuery(
    ANNOUNCEMENT_REACHABLE_AUDIENCE_COUNT_QUERY,
    {
      client,
      variables: {
        announcementId,
      },
    }
  );
  const announcement = data?.announcement?.announcement;
  const text = announcement?.text;
  const link = announcement?.link;
  const attachment = announcement?.attachments?.[0];
  const sendStartedAt = announcement?.sendStartedAt;
  const dueAt = announcement?.dueAt;
  const targetAllContacts = announcement?.targetAllContacts;

  const reachableAudienceCount =
    selectedAudienceCount?.reachableAudienceCount?.count;

  const defaultChannelOption = wizardGlobalProps?.channelsData?.channels[0]?.id;
  const controlledOutboundChannelId = outboundChannelId || defaultChannelOption;

  const recipientsAndMessageComplete =
    (reachableAudienceCount || targetAllContacts) &&
    (text?.length || attachment?.originalFilename);

  const renderAnnouncementPermissionCheck = () => {
    if ((reachableAudienceCount || targetAllContacts) && (text || attachment)) {
      return (
        <AnnouncementPermissionCheck
          dueAt={dueAt}
          hasPermission={hasPermission}
          togglePermission={togglePermission}
          hideCheckbox={!!sendStartedAt}
        />
      );
    }

    return null;
  };

  const handleOnSchedule = async ({
    dateTime,
    recurOption,
    endsAtDate,
    endsAtSelected,
  }) => {
    if (dateTime.isAfter()) {
      setSubmitting(true);
      setDeliveryDateTime(dateTime);

      const updateVars = {};

      if (!outboundChannelId) {
        updateVars.outboundChannelId = controlledOutboundChannelId;
      }

      updateVars.dueAt = moment(dateTime).utc().format();

      if (recurOption !== 'NEVER') {
        updateVars.repeats = recurOption.toUpperCase();
        if (endsAtSelected)
          updateVars.endsAt = `${moment(endsAtDate).utc().format('YYYY-MM-DD')}T23:59:59Z`;
        else updateVars.endsAt = null;
      } else {
        updateVars.repeats = null;
        updateVars.endsAt = null;
      }

      if (routeToGroup) {
        updateVars.routeToGroupId = routeToGroupId;
      } else if (
        data?.announcement?.announcement?.routeToGroupId &&
        !routeToGroup
      ) {
        updateVars.routeToGroupId = null;
      }

      const onSuccess = (willCloseWizard) => {
        if (willCloseWizard) {
          dispatch(
            openSnackbar(
              i18n.t('broadcasts-success-message', {
                defaultValue: 'Broadcast scheduled successfully',
              })
            )
          );
          dispatch(setAnnouncementStatus('SCHEDULED'));
          closeWizard('saved');
        }
      };

      const hasBeenSent = !!dueAt;

      // send updateAnnouncement mutation
      const data = await updateAnnouncement(updateVars, () =>
        onSuccess(hasBeenSent)
      );
      setSubmitting(false);

      // send sendAnnouncement mutation
      if (!data?.updateAnnouncement?.errors && !hasBeenSent) {
        await sendAnnouncement(false);
      }
    } else {
      setDeliveryError(i18n.t('slideouts-GroupMessageOverview-deliveryError'));
    }
  };

  const handleRouteToGroup = async (id) => {
    setRouteToGroupId(id);
  };

  useEffect(() => {
    setMessageName(announcement?.subject);
  }, [announcement?.subject]);

  useEffect(() => {
    if (
      !routeToGroup &&
      data?.announcement?.announcement?.routeToGroupId &&
      !routeToGroupId &&
      !loadedRouteToGroup
    ) {
      setRouteToGroupId(data?.announcement?.announcement?.routeToGroupId);
      setRouteToGroup(true);
      setLoadedRouteToGroup(true);
    }

    if (!routeToGroup && routeToGroupId && loadedRouteToGroup) {
      setRouteToGroupId(null);
    }
  }, [
    routeToGroupId,
    routeToGroup,
    data?.announcement?.announcement?.routeToGroupId,
    loadedRouteToGroup,
  ]);

  const handleConfirmCancelAnnouncement = async () => {
    const data = await updateAnnouncement(
      { dueAt: null },
      closeWizard('saved')
    );
    setOpenCancelModal(!openCancelModal);

    if (data?.updateAnnouncement?.errors) {
      setPageError(GENERIC_ERR);
    } else {
      dispatch(triggerRefetchAnnouncement(true));
      setDeliveryDateTime();
      dispatch(setAnnouncementStatus('DRAFT'));
      dispatch(
        openSnackbar(i18n.t('slideouts-GroupMessageOverview-cancelSnackbar'))
      );
    }
  };

  useEffect(() => {
    if (!outboundChannelId && !data) {
      return;
    }

    if (!outboundChannelId) {
      if (announcement?.outboundChannel?.id) {
        setOutboundChannelId(announcement?.outboundChannel?.id);
      } else {
        setPageError(
          i18n.t('slideouts-GroupMessageOverview-noBroadcastLine', {
            defaultValue:
              'You do not have access to an Announcement Line, please contact a manager or admin to obtain access to an announcement line.',
          })
        );
      }
    }
  }, [outboundChannelId, data, announcement?.outboundChannel?.id]);

  useEffect(() => {
    if (messageName?.length > 255) {
      setMessageError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else if (messageName?.length === 0 && isVisited) {
      setMessageError(
        i18n.t('broadcasts-name-validation', {
          defaultValue: 'Broadcast message name is required',
        })
      );
    } else {
      setMessageError('');
    }
  }, [isVisited, messageName]);

  useEffect(() => {
    refetch();
  }, [announcement, refetch]);

  useEffect(() => {
    if (announcement?.id && !announcementId) {
      setAnnouncementId(announcement?.id);
    }
  }, [announcement, announcementId, setAnnouncementId]);

  const updateAnnouncement = async (args, onSuccess) => {
    const { data } = await updateAnnouncementMutation({
      variables: {
        input: {
          id: announcementId,
          ...args,
        },
      },
    }).catch((e) => {
      console.error(e);
      setPageError(GENERIC_ERR);
    });

    const errors = data?.updateAnnouncement?.errors;
    if (errors) {
      if (errors[0].reason.includes('blank')) setMessageError(BLANK_ERR);
      else setPageError(GENERIC_ERR);
    } else {
      onSuccess?.();
    }

    return data;
  };

  const sendAnnouncement = debounce(
    async (sendToTab) => {
      const { data } = await sendAnnouncementMutation({
        variables: {
          id: announcementId,
        },
      }).catch((e) => {
        console.error(e);
        setPageError(GENERIC_ERR);
      });
      if (data?.sendAnnouncement?.errors) {
        setPageError(GENERIC_ERR);
      } else {
        const translatedMessage = sendToTab
          ? i18n.t('broadcasts-sentSuccess-message', {
              defaultValue: 'Broadcast Sent Successfully',
            })
          : i18n.t('broadcasts-success-message', {
              defaultValue: 'Broadcast scheduled successfully',
            });

        dispatch(openSnackbar(translatedMessage));
        dispatch(triggerRefetchBroadcast(true));
        dispatch(setAnnouncementStatus(sendToTab ? 'SENT' : 'SCHEDULED'));
        closeWizard('saved');
      }
    },
    1000,
    { leading: true }
  );

  const handleUpdateAndSendAnnouncement = async () => {
    setSubmitting(true);
    if (!outboundChannelId) {
      const data = await updateAnnouncement({
        outboundChannelId: controlledOutboundChannelId,
        routeToGroupId: routeToGroup ? routeToGroupId : null,
      });
      if (data?.updateAnnouncement?.errors) {
        setSubmitting(false);
        return;
      }
    }
    setSubmitting(false);
    dispatch(triggerRefetchBroadcast(true));
    await sendAnnouncement(true);
  };

  useEffect(() => {
    if (error) {
      setPageError(GENERIC_ERR);
      console.error(error);
    }
  }, [GENERIC_ERR, error]);

  useEffect(() => {
    dispatch(triggerRefetchBroadcast(false));
  }, [dispatch]);

  useEffect(() => {
    if (
      (announcement?.type && !announcementType) ||
      (announcement?.type && announcement?.type !== announcementType)
    ) {
      setAnnouncementType(announcement?.type);
    }
  }, [announcement, announcementType, setAnnouncementType]);

  const upsertBTM = async (page) => {
    if (!announcementId) {
      const { data } = await createAnnouncementMutation({
        variables: {
          input: {
            subject: messageName,
            outboundChannelId,
            type: announcementType,
            routeToGroupId: routeToGroup ? routeToGroupId : null,
          },
        },
      }).catch((e) => {
        console.error(e);
        setMessageError(GENERIC_ERR);
        setPageError(GENERIC_ERR);
      });

      const errors = data?.createAnnouncement?.errors;
      if (errors) {
        if (errors[0].reason.includes('blank')) setMessageError(BLANK_ERR);
        else setPageError(GENERIC_ERR);
      } else if (data?.createAnnouncement?.announcement?.id) {
        setAnnouncementId(data.createAnnouncement.announcement.id);
        if (page) setWizardPage(page);
      }
      return errors;
    } else if (
      messageName !== data?.announcement?.announcement?.subject ||
      outboundChannelId !==
        data?.announcement?.announcement?.outboundChannel?.id ||
      routeToGroupId !== data?.announcement?.announcement?.routeToGroupId ||
      (!routeToGroup && data?.announcement?.announcement?.routeToGroupId)
    ) {
      const data = await updateAnnouncement({
        subject: messageName,
        outboundChannelId,
        routeToGroupId: routeToGroup ? routeToGroupId : null,
      });
      const errors = data?.updateAnnouncement?.errors;
      if (errors) {
        if (errors[0].reason.includes('blank')) setMessageError(BLANK_ERR);
        else setPageError(GENERIC_ERR);
      } else if (page) setWizardPage(page);
      return errors;
    } else if (page) {
      setWizardPage(page);
    }
    return null;
  };

  const handleSaveAndExit = async () => {
    try {
      if (
        (messageName || announcementId || routeToGroupId) &&
        outboundChannelId
      ) {
        const errs = await upsertBTM();
        if (!errs) {
          dispatch(
            openSnackbar(
              i18n.t('slideouts-GroupMessageAudience-successfullySaved')
            )
          );
          closeWizard('saved');
        }
      } else {
        closeWizard('nosave');
      }
    } catch (e) {
      console.error(e);
      closeWizard('error');
    }
  };

  const handleMessageName = (val) => {
    setMessageError('');
    setIsVisited(true);
    setMessageName(val);
  };
  const groupMessageTitle = useMemo(() => {
    if (announcementId) return i18n.t('broadcasts-list-editBroadcast');

    return announcementType === ANNOUNCEMENT_TYPES.PROMOTIONAL
      ? i18n.t('broadcasts-list-createPromotional')
      : i18n.t('broadcasts-list-createInformational');
  }, [announcementType, announcementId]);

  const handleInitScheduleFlyout = () => {
    if (announcement?.repeats) setOpenPreviewModal(true);
    else setOpenDeliveryModal(true);
  };

  return (
    <GroupMessageTemplate
      title={groupMessageTitle}
      loading={loading || sendLoading}
      headerRightElement={
        <SaveAndExit
          closeWizard={handleSaveAndExit}
          sendStartedAt={!!sendStartedAt}
        />
      }
      showFooter={
        (!!recipientsAndMessageComplete && !sendStartedAt) ||
        (targetAllContacts && !!recipientsAndMessageComplete)
      }
      continueButtonText={i18n.t('slideouts-GroupMessageOverview-sendNow')}
      continueButtonDisabled={!hasPermission}
      continueButtonAction={handleUpdateAndSendAnnouncement}
      showScheduleButton
      hideBackButton
      deliveryDateTime={deliveryDateTime}
      toggleModalOpen={handleInitScheduleFlyout}
      toggleCancelModalOpen={() => setOpenCancelModal(!openCancelModal)}
      dueAt={dueAt}
      timezone={timezone}
      isRecurring={announcement?.repeats}
    >
      {loading || submitting ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <Container data-testid="group-message-overview-step">
          {pageError && <PageError>{pageError}</PageError>}
          <GroupMessageOverviewDetails
            subtitle={i18n.t('broadcasts-list-assignSender')}
          />
          <GroupMessageOverviewName
            error={error && isVisited}
            messageName={messageName}
            setMessageName={(val) => {
              handleMessageName(val);
            }}
            messageError={messageError}
            outboundChannelId={outboundChannelId}
            pageError={pageError}
            setOutboundChannelId={setOutboundChannelId}
            wizardGlobalProps={wizardGlobalProps}
            disabled={!!sendStartedAt}
            routeToGroup={routeToGroup}
            setRouteToGroup={() => setRouteToGroup(!routeToGroup)}
            routeToGroupId={routeToGroupId}
            handleRouteToGroup={handleRouteToGroup}
          />
          <OverviewItem
            title={i18n.t('slideouts-GroupMessageOverview-recipients')}
            subTitle={
              <RecipientSubTitle
                announcementId={announcementId}
                reachableAudienceCount={reachableAudienceCount}
                targetAllContacts={targetAllContacts}
              />
            }
            subTitleIsElement
            completed={!!reachableAudienceCount || !!targetAllContacts}
            allowNextStep={
              !!messageName && !!outboundChannelId && !messageError
            }
            onClickArrow={() => upsertBTM('GroupMessageAudience')}
            hasIcon
            iconName="contact"
            useButton
            buttonText={
              sendStartedAt
                ? i18n.t('slideouts-GroupMessageOverview-viewDetail')
                : i18n.t('slideouts-GroupMessageOverview-edit')
            }
          />
          <OverviewItem
            title={i18n.t('slideouts-GroupMessageOverview-message')}
            subTitle={
              <MessageSubTitle
                text={text}
                attachment={attachment}
                link={link}
              />
            }
            completed={!!text?.length || !!attachment?.originalFilename}
            onClickArrow={() => upsertBTM('GroupMessageContent')}
            allowNextStep={
              !!messageName && !!outboundChannelId && !messageError
            }
            hasIcon
            iconName="smsChannel"
            useButton
            buttonText={
              sendStartedAt
                ? i18n.t('slideouts-GroupMessageOverview-viewDetail')
                : i18n.t('slideouts-GroupMessageOverview-edit')
            }
          />
          {renderAnnouncementPermissionCheck()}
          <SchedulingFlyout
            isOpen={openDeliveryModal}
            onClose={() => setOpenDeliveryModal(false)}
            handleOnSchedule={handleOnSchedule}
            error={deliveryError}
            announcement={announcement}
          />
          <BroadcastRecurringFlyout
            isOpen={openPreviewModal && Boolean(announcement)}
            recurringBroadcast={announcement}
            onCloseRecurringFlyout={() => setOpenPreviewModal(false)}
            handleEdit={() => {
              setOpenPreviewModal(false);
              setOpenDeliveryModal(true);
            }}
          />
          <Modal
            isOpen={openCancelModal}
            size="sm"
            onRequestClose={() => setOpenCancelModal(!openCancelModal)}
            modalTitle={i18n.t('slideouts-GroupMessageOverview-returnDrafts')}
            primaryButtonText={i18n.t('slideouts-GroupMessageOverview-confirm')}
            primaryButtonOnClick={handleConfirmCancelAnnouncement}
            secondaryButtonText={i18n.t(
              'slideouts-GroupMessageOverview-cancel'
            )}
            secondaryButtonOnClick={() => setOpenCancelModal(!openCancelModal)}
          >
            {i18n.t('slideouts-GroupMessageOverview-returnMessage')}
          </Modal>
        </Container>
      )}
    </GroupMessageTemplate>
  );
};

GroupMessageOverviewCombined.propTypes = {
  wizardState: PropTypes.object.isRequired,
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardStateSetters: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
};

export default GroupMessageOverviewCombined;
