import React from 'react';
import styled from 'styled-components';
import CampaignTemplate from '../CampaignTemplate';
import { Heading2, Text } from '../../../../elements';
import CampaignGoal from '../subcomponents/CampaignGoal';
import { taskSvg } from '../../../../../svgs';
import OverviewDetails from '../../shared/OverviewDetails';
import THEMES from '../../../../styles/themes/app';
import type { Campaign } from '../../../../utils/helpers/types';
import SaveAndExit from '../../groupMessage/pages/subComponents/SaveAndExit';
import CampaignSummary from '../subcomponents/CampaignSummary';

const Container = styled.div`
  max-width: 958px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 40px;
`;

const GoalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
  margin-bottom: 14px;
  justify-content: center;
`;

const DetailsContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: ${THEMES.BOX_SHADOW};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
`;

enum ContentType {
  Broadcast = 'BROADCAST',
  Task = 'TAK',
  Survey = 'SURVEY',
}

type Content = {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  type: ContentType;
};

const CONTENT_OPTIONS: Content[] = [
  {
    title: 'Broadcast Message',
    subtitle: 'Here’s a small blurb explaining what this type of content is.',
    icon: <i className="ri-base-station-line" />,
    type: ContentType.Broadcast,
  },
  {
    title: 'Task',
    subtitle: '',
    icon: taskSvg,
    type: ContentType.Task,
  },
  {
    title: 'Survey',
    subtitle: '',
    icon: <i className="ri-survey-line" />,
    type: ContentType.Survey,
  },
];

interface CampaignContentsProps {
  wizardGlobalProps: {
    campaign: Campaign;
  };
  closeWizard: () => void;
}

// TODO: Translations
const CampaignContents = ({
  wizardGlobalProps,
  closeWizard,
}: CampaignContentsProps) => {
  const { campaign } = wizardGlobalProps;
  const { announcements, tasks } = campaign;
  return (
    <CampaignTemplate
      title="Review Campaign"
      showFooter={false}
      headerRightElement={<SaveAndExit closeWizard={closeWizard} />}
    >
      <Container>
        <CampaignSummary campaign={campaign} />
        <Heading2>Add Campaign Content</Heading2>
        <GoalWrapper>
          {CONTENT_OPTIONS.map((g: Content) => (
            <CampaignGoal
              key={g.title}
              {...g}
              isSelected={false}
              isMulti
              onSelect={() => {
                /* donothing */
              }}
            />
          ))}
        </GoalWrapper>
        <Heading2>Add Campaign Details</Heading2>
        <div>
          <OverviewDetails title="Your Content" subtitle="" />
          <DetailsContainer>
            {!(announcements.length && tasks.length) && (
              <Text>Nothing to see here</Text>
            )}
            {/* TODO: Add suggested content and user added content here
            {announcements.map((a) => a.id)}
            {tasks.map((t) => t.id)} */}
          </DetailsContainer>
        </div>
      </Container>
    </CampaignTemplate>
  );
};

export default CampaignContents;
