import React from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { Avatar, Heading3, Text } from '../../../elements';
import THEMES from '../../../styles/themes/app';

const CreateHeaderTitle = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: ${THEMES.BOX_SHADOW};
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const DetailsContainer = styled.div`
  width: 100%;
  margin-left: 20px;
`;

interface OverviewDetailsProps {
  title?: string;
  subtitle: string;
}

const OverviewDetails = ({ title, subtitle }: OverviewDetailsProps) => (
  <CreateHeaderTitle>
    <Avatar icon="details" />
    <DetailsContainer>
      <Heading3>{title || i18n.t('broadcasts-list-details')}</Heading3>
      <Text>{subtitle}</Text>
    </DetailsContainer>
  </CreateHeaderTitle>
);

export default OverviewDetails;
