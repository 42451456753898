import React from 'react';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { Button, Heading3, SquareBadge, Text } from '../../../../elements';
import THEMES from '../../../../styles/themes/app';
import type { Campaign } from '../../../../utils/helpers/types';
import { formatDate } from '../../../../utils/helpers/dateUtils';

const Container = styled.div`
  width: calc(100% - 32px);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${THEMES.BORDER_COLOR};
  background: ${THEMES.BACKGROUND_PRIMARY};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
`;

const getTextStyle = () => `
  font-weight: 400;
`;

const CAMPAIGN_TYPE_MAP: { [key: string]: string } = {
  PRODUCT: 'Promote a product',
  EVENT: 'Promote an event',
  ENGAGE: 'Reengage',
  EDUCATE: 'Educate',
  REFINE: 'Refine',
  CUSTOM: 'Custom',
};

interface CampaignSummaryProps {
  campaign: Campaign;
}

// TODO: Translations
const CampaignSummary = ({ campaign }: CampaignSummaryProps) => {
  const date = formatDate({ date: campaign.endDate, i18n });
  return (
    <Container>
      <div>
        <ContentWrapper>
          <Heading3>{campaign.name}</Heading3>
          <SquareBadge>{CAMPAIGN_TYPE_MAP[campaign.type]}</SquareBadge>
          <Text customStyle={getTextStyle}>Ends on {date}</Text>
        </ContentWrapper>
        <Text customStyle={getTextStyle}>{campaign.description}</Text>
      </div>
      <Button type="secondaryLight" disabled>
        Edit Details
      </Button>
    </Container>
  );
};

export default CampaignSummary;
