import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEditCampaigns, type ApolloClientLib } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import i18n from 'i18n-js';
import Wizard from '../index.tsx';
import {
  closeCreateSectionModal,
  openSnackbar,
  setActiveCreateCampaignSection,
  setActiveSidebar,
} from '../../../actions/general';
import CreateCampaign from './pages/CreateCampaign.tsx';
import type { Campaign, AppState } from '../../../utils/helpers/types.ts';
import CampaignContents from './pages/CampaignContents.tsx';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const createPages: () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: { start: boolean; component: any };
} = () => ({
  CreateCampaign: {
    start: true,
    component: CreateCampaign,
  },
  CampaignContents: {
    start: false,
    component: CampaignContents,
  },
});

// all wizard state will be contained here
const CampaignWizard = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [goal, setGoal] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [overrideWizardPage, setOverrideWizardPage] = useState('');
  const ffGrowthHubDashboard = useSelector(
    (state: AppState) => state?.accountData?.account.ff_growth_hub_dashboard
  );
  const ffCampaigns = useSelector(
    (state: AppState) => state?.accountData?.account.ff_campaigns
  );

  const handleOnClose = () => {
    dispatch(setActiveCreateCampaignSection(false));
    dispatch(closeCreateSectionModal());
    dispatch(setActiveSidebar('default'));
  };

  const client = useApolloClient();

  const handleError = () => {
    dispatch(
      openSnackbar(
        i18n.t('signin-ErrorMessagePage-errorSomethingWentWrong'),
        'error'
      )
    );
  };

  const handleSuccess = (res: Campaign) => {
    setCampaign(res);
    setOverrideWizardPage('CampaignContents');
  };

  const { handleCreateCampaign } = useEditCampaigns({
    client: client as unknown as ApolloClientLib,
    onError: handleError,
    onSuccess: (res) => handleSuccess(res as Campaign),
  });

  return (
    <Container data-testid="create-campaign-wizard">
      <Wizard
        pages={createPages()}
        wizardState={{ ffGrowthHubDashboard, ffCampaigns }}
        wizardPageOverride={overrideWizardPage}
        setWizardPageOverride={setOverrideWizardPage}
        wizardGlobalProps={{
          campaign,
          date,
          description,
          goal,
          handleCreateCampaign,
          name,
          setCampaign,
          setDate,
          setDescription,
          setGoal,
          setName,
        }}
        closeWizard={handleOnClose}
      />
    </Container>
  );
};

export default CampaignWizard;
