import moment from 'moment';
import type { I18NType } from './types';

interface FormatDateProps {
  date: string;
  i18n: I18NType;
  includeTime?: boolean;
  includeDayOfWeek?: boolean;
}

export const formatDate = ({
  date,
  i18n,
  includeTime = false,
  includeDayOfWeek = false,
}: FormatDateProps) => {
  const momentDate = moment(date);
  const currentLanguage = i18n.locale;
  const month = i18n.t(`date.abbr_month_names.${momentDate.month() + 1}`, {
    defaultValue: momentDate.format('MMM'),
  });
  let i18nDate;

  if (currentLanguage === 'es-MX')
    i18nDate = `${momentDate.date()} de ${month.toLocaleLowerCase()}. del ${momentDate.year()}`;
  else if (currentLanguage === 'fr-CA')
    i18nDate = `${momentDate.date()} ${month.toLocaleLowerCase()} ${momentDate.year()}`;
  else if (currentLanguage === 'en-US')
    i18nDate = `${month} ${momentDate.date()}, ${momentDate.year()}`;

  if (includeDayOfWeek) {
    const day = i18n.t(`date.abbr_day_names.${momentDate.day()}`, {
      defaultValue: momentDate.format('ddd'),
    });
    i18nDate =
      currentLanguage === 'en-US'
        ? `${day}, ${i18nDate}`
        : `${day} ${i18nDate}`;
  }

  if (includeTime) {
    const time = momentDate.format('LT');
    i18nDate = `${i18nDate} ${time}`;
  }

  return i18nDate;
};
