import React from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { Button, Switch, InputLabel } from '../../../elements';

const BottomBorder = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;

const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

interface SaveAndContinueProps {
  buttonActions?: {
    primary: (props: unknown) => void;
    secondary?: (props: unknown) => void;
  };
  buttonLabels?: {
    primary: string;
    secondary?: string;
  };
  buttonDisabled?: {
    primary: boolean;
    secondary?: string;
  };
  buttonValues?: {
    primary: boolean;
    secondary?: boolean;
  };
}

const SaveAndContinue = ({
  buttonLabels,
  buttonActions,
  buttonDisabled,
  buttonValues,
}: SaveAndContinueProps) => {
  return (
    <BottomBorder>
      {buttonLabels?.secondary && (
        <SecondaryContainer>
          <InputLabel contrast="colorHigh">
            {i18n.t('slideouts-GroupMessageRecipients-targetAllContacts')}
          </InputLabel>
          <Switch
            onCheck={buttonActions?.secondary}
            checked={buttonValues?.secondary}
            disabled={buttonDisabled?.secondary}
          />
        </SecondaryContainer>
      )}
      {buttonLabels?.primary && (
        <Button
          onClick={buttonActions?.primary}
          dataTestId="gmt-continue-button"
          disabled={buttonDisabled?.primary}
        >
          {buttonLabels.primary}
        </Button>
      )}
    </BottomBorder>
  );
};

export default SaveAndContinue;
