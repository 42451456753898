import React, { type KeyboardEventHandler } from 'react';
import styled from 'styled-components';
import { Button, Heading3, Text } from '../../../../elements';
import THEMES from '../../../../styles/themes/app';
import COLORS from '../../../../styles/colors';

const Container = styled.div<{ isSelected: boolean }>`
  background: ${({ isSelected }) =>
    isSelected ? THEMES.BACKGROUND_QUATERNARY : THEMES.BACKGROUND_PRIMARY};
  box-shadow: ${THEMES.BOX_SHADOW};
  border: 1px solid ${THEMES.BORDER_COLOR};
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 8px;
  width: 279px;
  height: 118px;
  text-align: center;
  svg {
    height: 34px;
    width: 34px;
    fill: ${({ isSelected }) =>
      isSelected ? COLORS.PK.BLUE : THEMES.FOREGROUND_LOW};
  }
  i {
    font-size: 34px;
    color: ${({ isSelected }) =>
      isSelected ? COLORS.PK.BLUE : THEMES.FOREGROUND_LOW};
  }
  input {
    margin: 0;
  }
`;

const Selector = styled.div`
  position: static;
`;

const ContentWrapper = styled.div<{ isMulti: boolean }>`
  margin-top: ${({ isMulti }) => (isMulti ? '-15px' : '-8px')};
  padding: 0 20px 6px 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
`;

const getTextStyle = () => `
  line-height: 20px;
`;

export interface CampaignGoalProps {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  isSelected: boolean;
  onSelect: () => void;
  isMulti?: boolean;
}

// TODO: Translations
const CampaignGoal = ({
  icon,
  title,
  subtitle,
  isSelected,
  onSelect,
  isMulti = false,
}: CampaignGoalProps) => {
  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') onSelect();
  };

  return (
    <Container
      role="button"
      tabIndex={0}
      onClick={onSelect}
      onKeyDown={handleKeyDown}
      isSelected={isSelected}
    >
      <Selector>
        {isMulti ? (
          <Button size="sm" disabled>
            Add
          </Button>
        ) : (
          <input
            type="radio"
            checked={isSelected}
            onChange={onSelect}
            tabIndex={-1}
          />
        )}
      </Selector>
      <ContentWrapper isMulti={isMulti}>
        {icon}
        <Heading3>{title}</Heading3>
        <Text customStyle={getTextStyle}>{subtitle}</Text>
      </ContentWrapper>
    </Container>
  );
};

export default CampaignGoal;
