import React, { useState, useEffect } from 'react';

export type ContentProps<T = unknown> = {
  wizardState?: { [key: string]: unknown };
  wizardStateSetters?: { [key: string]: () => void };
  setWizardPage?: React.Dispatch<React.SetStateAction<string>>;
  mutations?: { [key: string]: Promise<unknown> };
  wizardGlobalProps: T;
  closeWizard: () => void;
};

export type Pages<T = unknown> = {
  [key: string]: {
    start: boolean;
    component: React.ComponentType<ContentProps<T>>;
  };
};

export interface WizardProps<T = unknown> {
  closeWizard: () => void;
  mutations?: { [key: string]: Promise<unknown> };
  pages: Pages<T>;
  setWizardPageOverride?: React.Dispatch<React.SetStateAction<string>>;
  wizardGlobalProps: T;
  wizardPageOverride?: string;
  wizardState?: { [key: string]: unknown };
  wizardStateSetters?: { [key: string]: () => void };
}

// Make Wizard component generic
const Wizard = <T extends object>({
  closeWizard,
  mutations,
  pages,
  setWizardPageOverride,
  wizardGlobalProps = {} as T,
  wizardPageOverride = '',
  wizardState,
  wizardStateSetters,
}: WizardProps<T>) => {
  const startingPage =
    Object.keys(pages).find((name) => {
      const page = pages[name];
      return page?.start;
    }) || '';

  const [currentPage, setCurrentPage] = useState(startingPage);

  const Content = pages[currentPage.toString()]
    ?.component as React.ComponentType<ContentProps<T>>;

  useEffect(() => {
    if (wizardPageOverride.length && setWizardPageOverride) {
      setCurrentPage(wizardPageOverride);
      if (setWizardPageOverride) setWizardPageOverride('');
    }
  }, [setWizardPageOverride, wizardPageOverride]);

  return (
    <Content
      wizardState={wizardState}
      wizardStateSetters={wizardStateSetters}
      setWizardPage={setCurrentPage}
      mutations={mutations}
      wizardGlobalProps={wizardGlobalProps}
      closeWizard={closeWizard}
    />
  );
};

export default Wizard;
