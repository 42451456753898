import React, { type ChangeEvent, type Dispatch } from 'react';
import styled from 'styled-components';
import CampaignTemplate from '../CampaignTemplate';
import {
  Heading2,
  SelectDate,
  Text,
  TextArea,
  TextInput,
} from '../../../../elements';
import CampaignGoal from '../subcomponents/CampaignGoal';
import { graduationCapSvg, discountPercentSvg } from '../../../../../svgs';
import OverviewDetails from '../../shared/OverviewDetails';
import THEMES from '../../../../styles/themes/app';

const Container = styled.div`
  max-width: 958px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 40px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const GoalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
  margin-bottom: 14px;
  justify-content: center;
`;

const DetailsContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: ${THEMES.BOX_SHADOW};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
`;

enum CampaignType {
  Product = 'PRODUCT',
  Event = 'EVENT',
  Engage = 'ENGAGE',
  Custom = 'CUSTOM',
  Educate = 'EDUCATE',
  Refine = 'REFINE',
}

type Goal = {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  type: CampaignType;
};

const GOAL_OPTIONS: Goal[] = [
  {
    title: 'Promote a Product',
    subtitle: 'Promote a specific product or sale to your contacts',
    icon: discountPercentSvg,
    type: CampaignType.Product,
  },
  {
    title: 'Promote an Event',
    subtitle: '',
    icon: <i className="ri-calendar-event-line" />,
    type: CampaignType.Event,
  },
  {
    title: 'Reengage',
    subtitle: '',
    icon: <i className="ri-chat-smile-line" />,
    type: CampaignType.Engage,
  },
  {
    title: 'Educate',
    subtitle: '',
    icon: graduationCapSvg,
    type: CampaignType.Educate,
  },
  {
    title: 'Refine',
    subtitle: '',
    icon: <i className="ri-contacts-book-2-line" />,
    type: CampaignType.Refine,
  },
  {
    title: 'custom',
    subtitle: '',
    icon: <i className="ri-equalizer-line" />,
    type: CampaignType.Custom,
  },
];

interface CreateCampaignProps {
  wizardGlobalProps: {
    date: string;
    description: string;
    goal: string;
    handleCreateCampaign: (props: object) => Promise<unknown>;
    name: string;
    setDate: Dispatch<React.SetStateAction<string>>;
    setDescription: Dispatch<React.SetStateAction<string>>;
    setGoal: Dispatch<React.SetStateAction<string>>;
    setName: Dispatch<React.SetStateAction<string>>;
  };
}

// TODO: Translations
const CreateCampaign = ({ wizardGlobalProps }: CreateCampaignProps) => {
  const {
    date,
    description,
    goal,
    handleCreateCampaign,
    name,
    setDate,
    setDescription,
    setGoal,
    setName,
  } = wizardGlobalProps;

  return (
    <CampaignTemplate
      title="Create Campaign"
      createCampaign={() =>
        handleCreateCampaign({ name, type: goal, description, endDate: date })
      }
      createDisabled={!(goal && name && date)}
    >
      <Container>
        <HeaderWrapper>
          <Heading2>Select Campaign Goal</Heading2>
          <Text>
            By selecting a goal, we will help to populate your campaign audience
            and content to meet your needs.
          </Text>
        </HeaderWrapper>
        <GoalWrapper>
          {GOAL_OPTIONS.map((g: Goal) => (
            <CampaignGoal
              key={g.title}
              {...g}
              isSelected={goal === g.type}
              onSelect={() => setGoal(g.type)}
            />
          ))}
        </GoalWrapper>
        <Heading2>Add Campaign Details</Heading2>
        <div>
          <OverviewDetails subtitle="Enter a name and description for your campaign" />
          <DetailsContainer>
            <TextInput
              label="Campaign Name"
              type="text"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              placeholder="Enter campaign name"
              dataTestId="campaign-name-input"
              isRequired
            />
            <SelectDate
              label="Campaign End Date"
              value={date}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDate(e.target.value)
              }
              dataTestId="campaign-end-date-input"
              isRequired
            />
            <TextArea
              label="Description"
              value={description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(e.target.value)
              }
              rows={4}
              placeholder="Enter campaign description"
              dataTestId="campaign-description-input"
            />
          </DetailsContainer>
        </div>
      </Container>
    </CampaignTemplate>
  );
};

export default CreateCampaign;
