import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DetectClickOutside extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  checkClassByDepth = (element, className, depth) => {
    if (depth === 0 || !element) return false;

    if (element?.getAttribute?.('class')?.includes(className)) return true;

    return this.checkClassByDepth(element.parentNode, className, depth - 1);
  };

  handleClick = (e) => {
    const { onClickOutside, classesNotOutside, depthCheck = 5 } = this.props;
    let foundToBeInside = false;

    classesNotOutside.forEach((className) => {
      if (this.checkClassByDepth(e.target, className, depthCheck)) {
        foundToBeInside = true;
      }
    });

    if (!this.wrapper.contains(e.target) && !foundToBeInside) onClickOutside(e);
  };

  render() {
    return (
      <div
        ref={(wrapper) => {
          this.wrapper = wrapper;
        }}
        data-testid="detect-click-outside"
      >
        {this.props.children}
      </div>
    );
  }
}

DetectClickOutside.propTypes = {
  children: PropTypes.node.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  classesNotOutside: PropTypes.array,
  depthCheck: PropTypes.number,
};

DetectClickOutside.defaultProps = {
  classesNotOutside: [],
  depthCheck: 5,
};

export default DetectClickOutside;
