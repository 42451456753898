import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import { Heading2, Button, Heading5, Text } from '../../../elements';
import THEMES from '../../../styles/themes/app';

import COLORS from '../../../styles/colors';
import SaveAndContinue from '../shared/SaveAndContinue.tsx';

// generic colors
const FOOTER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const headerSideSelectionWidth = 200;

const TemplateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
`;

const Header = styled.div`
  min-height: 80px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: flex;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const HeaderOption = styled.div`
  width: ${headerSideSelectionWidth}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
`;

const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  overflow-y: auto;
`;

const FooterContainer = styled.div`
  min-height: 80px;
  background-color: ${FOOTER};
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const RightFooterContent = styled.div`
  display: flex;
  align-items: center;
`;

const ScheduledDeliveryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ScheduledDeliveryHeader = styled.div`
  margin-bottom: 4px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  width: 100%;
`;

const CalendarIcon = styled.i`
  font-size: 24px;
  margin-right: 16px;
  color: ${THEMES.COLORED_FOREGROUND_HIGH};
`;

const EditIcon = styled.i`
  font-size: 20px !important;
`;

const IconWrapper = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const getNoDecoration = () => `text-decoration: none !important;`;

const GroupMessagingTemplate = ({
  backButtonAction,
  centerFooterElement,
  children,
  continueButtonAction,
  continueButtonDisabled,
  continueButtonText,
  customHeaderCenter,
  deliveryDateTime,
  dueAt,
  headerRightElement,
  hideBackButton,
  hideContinueButton,
  hideSeconaryContinueButton,
  loading,
  secondaryContinueButtonAction,
  secondaryContinueButtonDisabled,
  secondaryContinueButtonText,
  showFooter,
  showScheduleButton,
  timezone,
  title,
  toggleCancelModalOpen,
  toggleModalOpen,
  bottomFooterProps,
  templateButtonAction,
  hideTemplateButton,
  isRecurring,
}) => (
  <TemplateContainer data-testid="group-message-template">
    <Header>
      <HeaderOption />
      <Title>
        {customHeaderCenter || (
          <span data-testid="group-message-template-title">
            <Heading2>{title}</Heading2>
          </span>
        )}
      </Title>
      <HeaderOption>{headerRightElement}</HeaderOption>
    </Header>
    <Body>{children}</Body>
    {showFooter && (
      <FooterContainer data-testid="groun-message-template-footer">
        {!hideBackButton ? (
          <Button
            disabled={loading}
            type="tertiary"
            onClick={backButtonAction}
            dataTestId="gmt-back-button"
          >
            {i18n.t('slideouts-GroupMessageOverview-back')}
          </Button>
        ) : (
          <div />
        )}
        {centerFooterElement}
        <RightFooterContent>
          {showScheduleButton ? (
            deliveryDateTime || dueAt ? (
              <ScheduledDeliveryWrapper>
                <CalendarIcon className="ri-calendar-event-fill" />
                <DateWrapper>
                  <ScheduledDeliveryHeader>
                    <Heading5 contrast="colorHigh">
                      {i18n.t('slideouts-GroupMessageRecipients-scheduledFor')}
                    </Heading5>
                  </ScheduledDeliveryHeader>
                  <Text contrast="colorHigh">
                    {i18n.strftime(
                      new Date(deliveryDateTime || dueAt),
                      '%A, %B %-d, %Y %-I:%M %p'
                    )}{' '}
                    {timezone}
                  </Text>
                </DateWrapper>
                <IconWrapper>
                  <Button
                    disabled={loading}
                    type="link"
                    noOutline
                    onClick={toggleModalOpen}
                    customStyle={getNoDecoration}
                  >
                    <EditIcon
                      className={isRecurring ? 'ri-eye-fill' : 'ri-pencil-fill'}
                    />
                  </Button>
                  <Button
                    disabled={loading}
                    type="link"
                    noOutline
                    onClick={toggleCancelModalOpen}
                    customStyle={getNoDecoration}
                  >
                    <EditIcon className="ri-close-line" />
                  </Button>
                </IconWrapper>
              </ScheduledDeliveryWrapper>
            ) : (
              <Button
                type="tertiary"
                onClick={toggleModalOpen}
                customStyle={() => 'margin-right: 8px;'}
                disabled={continueButtonDisabled || loading}
              >
                {i18n.t('slideouts-GroupMessageRecipients-schedule')}
              </Button>
            )
          ) : null}
          {!hideSeconaryContinueButton &&
          secondaryContinueButtonText &&
          !dueAt ? (
            <div style={{ paddingRight: 8 }}>
              <Button
                disabled={secondaryContinueButtonDisabled || loading}
                onClick={secondaryContinueButtonAction}
                dataTestId="gmt-secondary-continue-button"
              >
                {secondaryContinueButtonText}
              </Button>
            </div>
          ) : null}

          {!hideTemplateButton && !deliveryDateTime && !dueAt ? (
            <div style={{ paddingRight: 8 }}>
              <Button
                onClick={templateButtonAction}
                disabled={continueButtonDisabled || loading}
                type="tertiary"
                dataTestId="gmt-template-button"
              >
                {i18n.t('broadcasts-BTMFlyout-saveAsTemplate', {
                  defaultValue: 'Save as template',
                })}
              </Button>
            </div>
          ) : null}

          {!hideContinueButton && !deliveryDateTime && !dueAt ? (
            <SaveAndContinue
              buttonLabels={{
                primary: continueButtonText,
                secondary: bottomFooterProps?.secondaryLabel || null,
              }}
              buttonActions={{
                primary: continueButtonAction,
                secondary: bottomFooterProps?.secondaryAction,
              }}
              buttonDisabled={{
                primary: continueButtonDisabled || loading,
                secondary: bottomFooterProps?.disabled,
              }}
              buttonValues={{
                secondary: bottomFooterProps?.secondaryValue,
              }}
            />
          ) : null}
        </RightFooterContent>
      </FooterContainer>
    )}
  </TemplateContainer>
);

GroupMessagingTemplate.propTypes = {
  backButtonAction: PropTypes.func,
  centerFooterElement: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  continueButtonAction: PropTypes.func,
  continueButtonDisabled: PropTypes.bool,
  continueButtonText: PropTypes.string,
  customHeaderCenter: PropTypes.element,
  deliveryDateTime: PropTypes.object,
  dueAt: PropTypes.string,
  headerRightElement: PropTypes.element,
  hideBackButton: PropTypes.bool,
  hideContinueButton: PropTypes.bool,
  hideSeconaryContinueButton: PropTypes.bool,
  loading: PropTypes.bool,
  secondaryContinueButtonAction: PropTypes.func,
  templateButtonAction: PropTypes.func,
  secondaryContinueButtonDisabled: PropTypes.bool,
  secondaryContinueButtonText: PropTypes.string,
  showFooter: PropTypes.bool,
  hideTemplateButton: PropTypes.bool,
  showScheduleButton: PropTypes.bool,
  timezone: PropTypes.string,
  title: PropTypes.string,
  toggleCancelModalOpen: PropTypes.func,
  toggleModalOpen: PropTypes.func,
  bottomFooterProps: PropTypes.object,
  isRecurring: PropTypes.bool,
};

GroupMessagingTemplate.defaultProps = {
  backButtonAction: () => {},
  centerFooterElement: null,
  continueButtonAction: () => {},
  templateButtonAction: () => {},
  continueButtonDisabled: false,
  hideTemplateButton: true,
  continueButtonText: '',
  customHeaderCenter: null,
  deliveryDateTime: null,
  dueAt: '',
  headerRightElement: null,
  hideBackButton: false,
  hideContinueButton: false,
  hideSeconaryContinueButton: false,
  loading: false,
  secondaryContinueButtonAction: () => {},
  secondaryContinueButtonDisabled: false,
  secondaryContinueButtonText: '',
  showFooter: false,
  showScheduleButton: false,
  timezone: '',
  title: '',
  toggleCancelModalOpen: () => {},
  toggleModalOpen: () => {},
  bottomFooterProps: {},
  isRecurring: false,
};

export default GroupMessagingTemplate;
