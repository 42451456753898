import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { Heading2 } from '../../../elements';
import THEMES from '../../../styles/themes/app';

import COLORS from '../../../styles/colors';
import SaveAndContinue from '../shared/SaveAndContinue';

// generic colors
const FOOTER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const headerSideSelectionWidth = 200;

const TemplateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
`;

const Header = styled.div`
  min-height: 80px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: flex;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const HeaderOption = styled.div`
  width: ${headerSideSelectionWidth}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
  button {
    z-index: 10;
  }
`;

const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  overflow-y: auto;
`;

const FooterContainer = styled.div`
  min-height: 80px;
  background-color: ${FOOTER};
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
`;

const RightFooterContent = styled.div`
  display: flex;
  align-items: center;
`;

interface CampaignTemplateProps {
  children: React.ReactNode;
  createCampaign?: () => void;
  createDisabled?: boolean;
  headerRightElement?: React.ReactNode;
  showFooter?: boolean;
  title: string;
}

// TODO: Translations
const CampaignTemplate = ({
  children,
  createCampaign,
  createDisabled = true,
  headerRightElement,
  showFooter = true,
  title,
}: CampaignTemplateProps) => (
  <TemplateContainer data-testid="campaign-template">
    <Header>
      <HeaderOption />
      <Title>
        <span data-testid="campaign-template-title">
          <Heading2>{title}</Heading2>
        </span>
      </Title>
      <HeaderOption>{headerRightElement}</HeaderOption>
    </Header>
    <Body>{children}</Body>
    {showFooter && createCampaign && (
      <FooterContainer data-testid="campaign-template-footer">
        <RightFooterContent>
          <SaveAndContinue
            buttonLabels={{
              primary: 'Create Campaign',
            }}
            buttonActions={{
              primary: createCampaign,
            }}
            buttonDisabled={{
              primary: createDisabled,
            }}
          />
        </RightFooterContent>
      </FooterContainer>
    )}
  </TemplateContainer>
);

export default CampaignTemplate;
