// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./pk-svgs/bard-fill.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./pk-svgs/expand-diagonal-2-fill.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./pk-svgs/collapse-diagonal-2-fill.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./pk-svgs/arrow-right-up-box-blue-line.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./pk-svgs/pk-prohibited-line.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pk-bard-fill {
    width:16px;
    height:16px;
    background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    margin:0;
    padding:0;
  }

.pk-expand-diagonal-2-fill {
  width:22px;
  height:22px;
  background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  margin:0;
  padding:0;
}

.pk-collapse-diagonal-2-fill {
  width: 22px;
  height: 22px;
  background-image:url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  margin:0;
  padding:0;
}

.pk-arrow-right-up-box-blue-line {
  width: 36px;
  height: 36px;
  display: block;
  background-image:url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  margin:0;
  padding:0;
}

.pk-prohibited-line {
  width: 36px;
  height: 36px;
  display: block;
  background-image:url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  margin:0;
  padding:0;
}`, "",{"version":3,"sources":["webpack://./src/assets/pk-svg.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,wDAA+C;IAC/C,QAAQ;IACR,SAAS;EACX;;AAEF;EACE,UAAU;EACV,WAAW;EACX,wDAA4D;EAC5D,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wDAA8D;EAC9D,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,wDAAkE;EAClE,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,wDAAwD;EACxD,QAAQ;EACR,SAAS;AACX","sourcesContent":[".pk-bard-fill {\n    width:16px;\n    height:16px;\n    background-image:url('./pk-svgs/bard-fill.svg');\n    margin:0;\n    padding:0;\n  }\n\n.pk-expand-diagonal-2-fill {\n  width:22px;\n  height:22px;\n  background-image:url('./pk-svgs/expand-diagonal-2-fill.svg');\n  margin:0;\n  padding:0;\n}\n\n.pk-collapse-diagonal-2-fill {\n  width: 22px;\n  height: 22px;\n  background-image:url('./pk-svgs/collapse-diagonal-2-fill.svg');\n  margin:0;\n  padding:0;\n}\n\n.pk-arrow-right-up-box-blue-line {\n  width: 36px;\n  height: 36px;\n  display: block;\n  background-image:url('./pk-svgs/arrow-right-up-box-blue-line.svg');\n  margin:0;\n  padding:0;\n}\n\n.pk-prohibited-line {\n  width: 36px;\n  height: 36px;\n  display: block;\n  background-image:url('./pk-svgs/pk-prohibited-line.svg');\n  margin:0;\n  padding:0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
