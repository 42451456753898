import { useMutation, ApolloClient } from "@apollo/client";

import CREATE_CAMPAIGN_MUTATION from "../../../graphql/mutation/CreateCampaignMutation.graphql";
import type { Campaign } from "./types";

interface UseEditCampaignsProps {
  client: ApolloClient<object>;
  onError: (error?: string) => void;
  onSuccess: (campaign: object) => void;
}

const useEditCampaigns = ({
  client,
  onError,
  onSuccess,
}: UseEditCampaignsProps) => {
  const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN_MUTATION, {
    client,
  });

  // const [updateCampaignMutation] = useMutation(UPDATE_CAMPAIGN_MUTATION, {
  //   client,
  // });

  // const [deleteCampaignMutation] = useMutation(DELETE_CAMPAIGN_MUTATION, {
  //   client,
  // });

  // const [addAnnouncementToCampaignMutation] = useMutation(
  //   ADD_ANNOUNCEMENT_TO_CAMPAIGN_MUTATION,
  //   { client }
  // );

  // const [addTaskToCampaignMutation] = useMutation(
  //   ADD_TASK_TO_CAMPAIGN_MUTATION,
  //   { client }
  // );

  // const [removeAnnouncementFromCampaign] = useMutation(
  //   REMOVE_ANNOUNCEMENT_FROM_CAMPAIGN_MUTATION,
  //   { client }
  // );

  // const [removeTaskFromMutation] = useMutation(
  //   REMOVE_TASK_FROM_CAMPAIGN_MUTATION,
  //   { client }
  // );

  const handleCreateCampaign = async ({
    name,
    type,
    description,
    endDate,
  }: Campaign) => {
    const res = await createCampaignMutation({
      variables: {
        name,
        type,
        description,
        endDate,
      },
    }).catch((e) => onError(e));
    if (res && res.data?.createCampaign) {
      onSuccess(res.data.createCampaign);
    } else onError();
  };

  return {
    handleCreateCampaign,
  };
};

export default useEditCampaigns;
